@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000c25;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

a {
  color: #fff;
  text-decoration: none;
}

.wallet-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #0074c2;
  width: 90%;
  max-width: 600px;
}

.wallet-list a {
  margin: 50px 0;
}

.wallet-list img {
  height: 50px;
  opacity: 0.8;
}

.wallet-list a:hover img {
  opacity: 1;
}

.wallet-header {
  padding: 10px;
  width: 90%;
  max-width: 600px;
  text-align: center;
  background-color: #0074c2;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-header svg {
  height: 25px;
  margin-right: 5px;
  fill: #fff;
}

.wallet-divider {
  width: 80%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
}

/********************************************************* HEADER *****************************************************************/

.header-ctn {
  width: 100%;
  padding: 0 50px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.header-ctn h1 {
  display: inline-block;
  position: relative;
  font-size: 23px;
  margin: 0;
  z-index: 99;
}

.header-ctn img {
  width: 120px;
}

.blip {
  height: 25px;
  position: absolute;
  left: 80px;
  top: 40px;
}

.nav-ctn a,
.navlink {
  font-size: 16px;
  margin-left: 40px;
  display: inline-block;
  cursor: pointer;
}

.nav-ctn a:first-child:hover,
.navlink:hover {
  color: #0074c2;
}

/********************************************************* GLOW BUTTON *****************************************************************/

.glow-on-hover {
  border: none;
  outline: none;
  color: #fff !important;
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: 16px;
  padding: 5px 16px;
  font-family: 'Poppins';
  user-select: none;
  border-radius: 2px;
  transition: all 200ms ease-in-out;
}

.glow-on-hover:hover {
  transform: translateY(-2px);
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #0074c2, #ff21d0);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 3px;
}

.glow-on-hover:hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #ff21d0);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #001137;
}

.glitch {
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/************************************************ Landing Page *****************************************************************/

:root {
  --row-height: 40px;
  --cell-spacing: 2px;
  --row-background: #1a253b;
  --check-col-width: 60px;
}

.loading-ctn {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-top: 100px;
}

.loading-ctn-inner {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-ctn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px 50px 50px;
  height: 100vh;
  min-height: 800px;
  align-items: center;
  position: relative;
}

.square-ctn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.51);
  border: 2px solid #0074c2;
  padding: 20px 40px 40px 40px;
  z-index: 999;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  margin: 0;
  padding: 0;
}

.landingbackground-image {
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -7;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}

.big-e {
  width: 400px;
  display: inline-block;
  z-index: 2;
  margin-left: 24px;
  margin-bottom: 20px;
  animation: glitch 1s linear infinite;
}

.main-ctn h1 {
  z-index: 4;
  font-size: 70px;
  letter-spacing: -4px;
  margin: 0;
}

.select-line-ctn {
  display: flex;
  align-items: center;
}

.currency-option {
  display: flex;
  align-items: center;
}

.currency-option img {
  width: 20px;
  margin-right: 6px;
}

.fiat-icon {
  width: 20px;
  height: 20px;
  background-color: #484e5e;
  font-size: 11px;
  /* font-weight: 700; */
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.main-ctn h2,
.pair-page-selector-ctn h2 {
  z-index: 4;
  font-size: 40px;
  margin: 0;
  display: flex;
  align-items: center;
}

.main-ctn h2 span,
.pair-page-selector-ctn h2 span {
  background: #0074c2;
  padding: 0px 10px;
  height: 58px;
  border-radius: 4px;
  font-size: 30px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  position: relative;
}

.select-ctn {
  display: inline-block;
  margin-left: 10px;
  z-index: 4;
  width: 200px;
  z-index: 999;
}

.explainer-p {
  max-width: 700px;
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
}

.see-all-link {
  cursor: pointer;
  font-size: 14px;
  transition: all 200ms ease-in-out;
  margin-top: 15px;
  border: 1px solid #0074c2;
  padding: 5px 15px;
}

.see-all-link:hover {
  color: #0074c2;
}

/************************************************ PAIR PAGE *****************************************************************/

.pair-page-selector-ctn {
  width: 100%;
  background-color: #071a37;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.pair-page-selector-ctn h2 {
  z-index: 4;
  font-size: 30px;
  margin: 0;
  display: flex;
  align-items: center;
}

.pair-page-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgb(7, 26, 55);
  padding-top: 30px;
  background: -moz-linear-gradient(
    0deg,
    rgba(7, 26, 55, 0) 0%,
    rgba(7, 26, 55, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(7, 26, 55, 0) 0%,
    rgba(7, 26, 55, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(7, 26, 55, 0) 0%,
    rgba(7, 26, 55, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#071a37",endColorstr="#071a37",GradientType=1);
}

.pair-page-subheadline {
  font-size: 18px;
  margin-bottom: 30px;
}

.pair-page-subheadline span {
  color: #0074c2;
  font-weight: 700;
}

.exchange-tile-ctn {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1000px;
  padding: 0 20px;
}

.score-disclaimer {
  font-size: 12px;
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 30px;
}

.score-disclaimer a {
  opacity: 0.4;
  text-decoration: underline;
}

.score-disclaimer a:hover {
  color: #0074c2;
  opacity: 1;
}

/*********************************** Exchange Tile *****************************************/

.tile-ctn-outer {
  width: 50%;
  margin-bottom: 20px;
  display: flex;
}

.tile-ctn {
  width: 100%;
  padding: 0 10px;
  display: flex;
}

.tile-border {
  width: 100%;
  z-index: 99;
  background-color: #273498;
  background-image: linear-gradient(-45deg, #273498, #0074c2 53%, #00abe7);
  padding: 1px;
  text-align: center;
  line-height: 1.9em;
  font-size: 18px;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%,
    0 0
  );
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.tile-inner {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #071a37;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 20px),
    calc(100% - 20px) 100%,
    0 100%,
    0 0
  );
}

.tile-logo-score-ctn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tile-scorebox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tile-score {
  font-weight: 700;
  font-size: 28px;
  line-height: 1em;
}

.tile-score-label {
  color: #0074c2;
  font-size: 10px;
  line-height: 1em;
}

.tile-img {
  max-height: 50px;
  max-width: 220px;
}

.tile-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 20px 0;
}

.tile-label {
  font-size: 12px;
  color: #9a9da5;
  margin-bottom: 5px;
  line-height: 1em;
}

.tile-pairs-list {
  font-size: 16px;
  text-align: left;
  line-height: 1.5em;
  font-weight: 700;
  margin-bottom: 20px;
}

.tile-btn {
  display: inline-block;
  background-color: #071a37;
  font-weight: 700;
  color: #0074c2;
  padding: 6px 18px 6px 0;
  font-size: 14px;
  line-height: 1.2em;
  margin-top: auto;
  clip-path: polygon(
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 0
  );
  transition: all 200ms ease-in-out;
}

.tile-btn:hover {
  color: #fff;
  background-color: #0074c2;
  padding: 6px 18px 6px 18px;
}

.scorecard-link {
  position: relative;
}

.scorecard-link::after {
  opacity: 0;
  position: absolute;
  right: 0px;
  content: '>';
  top: 0;
  transition: all 200ms ease-in-out;
}

.scorecard-link:hover::after {
  opacity: 1;
  right: -10px;
}

/************************************************ BANNER *****************************************************************/

.vitem h5 {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin: 0%;
  padding: 6px 0;
}

.vitem img {
  height: 15px;
  margin-right: 5px;
}

.vwrap,
.vitem {
  height: 30px;
  line-height: 30px;
}

.vwrap {
  overflow: hidden;
  background: #0074c2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

.vitem {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* (D) ANIMATION - MOVE ITEMS FROM TOP TO BOTTOM */
/* CHANGE KEYFRAMES IF YOU ADD/REMOVE ITEMS */
.vmove {
  position: relative;
}
@keyframes tickerv {
  0% {
    bottom: 0;
  } /* FIRST ITEM */
  30% {
    bottom: 30px;
  } /* SECOND ITEM */
  60% {
    bottom: 60px;
  } /* THIRD ITEM */
  90% {
    bottom: 90px;
  } /* FORTH ITEM */
  100% {
    bottom: 90px;
  } /* BACK TO FIRST */
}
.vmove {
  animation-name: tickerv;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(1, 0, 0.5, 0);
  animation-direction: alternate-reverse;
}
.vmove:hover {
  animation-play-state: paused;
}

/************************************************ TICKER *****************************************************************/

.ticker-ctn-main {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.51);
  z-index: 2;
  position: absolute;
  bottom: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticker-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticker-group-ctn {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 20px;
}

.ticker-group-ctn img {
  width: 30px;
  margin-right: 5px;
}

.ticker-price {
  margin: 0 8px;
}

/*********************************** MODAL *****************************************/

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 13, 41, 0.8);
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
}

.modal-border {
  width: 80%;
  max-width: 700px;
  z-index: 9999;
  background-color: #273498;
  background-image: linear-gradient(151deg, #273498, #0074c2 53%, #00abe7);
  padding: 2px;
  text-align: center;
  line-height: 1.9em;
  font-size: 18px;
  clip-path: polygon(100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px), 0 0);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-inner {
  width: 100%;
  padding: 30px;
  background-color: #020c23;
  clip-path: polygon(100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px), 0 0);
}

/*********************************** MEDIA *****************************************/

@media (max-width: 910px) {
  .glitch {
    animation: none;
  }

  .glitch::before {
    animation: none;
  }
  .glitch::after {
    animation: none;
  }

  .glitch::before,
  .glitch::after {
    display: none;
  }

  .header-ctn {
    width: 100%;
    padding: 15px 10px;
  }

  .header-ctn h1 {
    font-size: 18px;
  }

  .blip {
    height: 15px;
    left: 30px;
    top: 30px;
  }

  .nav-ctn a,
  .navlink {
    font-size: 12px;
    margin-left: 10px;
  }

  .nav-ctn a:first-child {
    display: none;
  }

  .glow-on-hover {
    font-size: 12px !important;
    padding: 3px 10px;
    border-radius: 1px;
  }

  .modal-border {
    width: 98%;
    max-width: unset;
    line-height: 1.8em;
    font-size: 14px;
  }

  .modal-inner {
    padding: 20px 10px;
  }

  /*********************************** NEW *****************************************/

  /* .vwrap {
    display: none;
  } */

  .main-ctn {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: center;
    padding: 40px 10px 80px;
  }

  .big-e {
    width: 200px;
    margin-left: 24px;
    margin-bottom: 20px;
  }

  .main-ctn h1 {
    font-size: 35px;
    letter-spacing: -2px;
  }

  .square-ctn {
    padding: 20px;
  }

  .main-ctn h2,
  .pair-page-selector-ctn h2 {
    z-index: 4;
    font-size: 18px;
  }

  .main-ctn h2 span,
  .pair-page-selector-ctn h2 span {
    height: 28px;
    border-radius: 3px;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin: 0 5px;
    position: relative;
  }

  .react-select__control {
    font-size: 18px !important;
    display: block;
    width: 150px;
  }

  .pair-page-selector-ctn h2 {
    font-size: 16px;
  }

  .pair-page-selector-ctn h2 span {
    height: 38px;
    border-radius: 3px;
    font-size: 16px;
    margin: 0 4px;
  }

  .react-select2__control {
    font-size: 16px !important;
    display: block;
    width: 125px;
  }

  .select-line-ctn {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .select-ctn {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  .explainer-p {
    font-size: 12px;
    margin-top: 10px;
  }

  .select2-ctn {
    margin-left: 5px;
    margin-top: 0px;
    width: auto;
  }

  .pair-page-subheadline {
    font-size: 16px;
    margin-bottom: 25px;
    padding: 0 20px;
    text-align: center;
  }

  .tile-ctn-outer {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
  }

  /************************************************ TICKER *****************************************************************/

  .ticker-ctn-main {
    padding: 10px 20px;
    /* display: none; */
  }

  .ticker-ctn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
  }

  .ticker-group-ctn {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 12px;
    margin: 0 10px;
  }

  .ticker-group-ctn img {
    width: 15px;
    margin-right: 5px;
    display: none;
  }

  .ticker-price {
    margin: 0 0;
  }

  .tile-inner {
    padding: 20px;
  }

  .tile-score {
    font-size: 25px;
  }

  .tile-img {
    max-height: 40px;
    max-width: 200px;
}

  .video-ctn,
  video {
    display: none;
  }
  .wallet-list img {
    height: 30px;
  }
}
